var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Breadcrumb'),_c('h1',[_vm._v(_vm._s(this.pageTitle))]),_c('hr',{staticClass:"separate-line"}),_c('el-form',{attrs:{"label-position":"top"}},[_c('el-form-item',{attrs:{"label":_vm.$t('quickEnroll.pleaseSelectOne')}},[_c('el-radio-group',{model:{value:(_vm.enrollType),callback:function ($$v) {_vm.enrollType=$$v},expression:"enrollType"}},[(_vm.isRoleStudent())?_c('el-radio-button',{attrs:{"label":"self"}},[_vm._v(" "+_vm._s(_vm.$t("quickEnroll.enrollForYourself"))+" ")]):_vm._e(),(_vm.isRoleParent())?_c('el-radio-button',{attrs:{"label":"parent"}},[_vm._v(" "+_vm._s(_vm.$t("quickEnroll.i18n1"))+" ")]):_vm._e(),(_vm.isRoleAdmin())?_c('el-radio-button',{attrs:{"label":"admin"}},[_vm._v(" "+_vm._s(_vm.$t("quickEnroll.i18n1"))+" (Admin) ")]):_vm._e()],1)],1),(_vm.studentListVisible)?_c('el-form-item',{attrs:{"label":_vm.$t('quickEnroll.i18n2')}},[_c('el-alert',{staticClass:"mb-3",attrs:{"title":_vm.$t('enrollment.step2.notes'),"closable":false,"type":"error"}}),_c('div',[[_c('el-checkbox-group',{model:{value:(_vm.checkedStudent),callback:function ($$v) {_vm.checkedStudent=$$v},expression:"checkedStudent"}},_vm._l((_vm.students),function(student){return _c('div',{key:student.student_user_id},[_c('el-checkbox',{attrs:{"label":student.student_user_id}},[_vm._v(" "+_vm._s(_vm.userMethods.displayName( student.first_name, student.last_name ))+" "),_c('b',[_vm._v("["+_vm._s(student.email)+"]")])])],1)}),0),_c('div',[_c('el-checkbox',{attrs:{"label":-1},model:{value:(_vm.hasNewStudent),callback:function ($$v) {_vm.hasNewStudent=$$v},expression:"hasNewStudent"}},[_vm._v(" "+_vm._s(_vm.$t("quickEnroll.New Student"))+" "),_c('el-input',{staticClass:"ml-2",staticStyle:{"width":"150px"},attrs:{"placeholder":"First name","disabled":!_vm.hasNewStudent},on:{"change":function($event){return _vm.checkName(
                    _vm.parentNewStudentFirstName,
                    _vm.parentNewStudentLastName
                  )}},model:{value:(_vm.parentNewStudentFirstName),callback:function ($$v) {_vm.parentNewStudentFirstName=$$v},expression:"parentNewStudentFirstName"}}),_c('el-input',{staticClass:"ml-2",staticStyle:{"width":"150px"},attrs:{"placeholder":"Last name","disabled":!_vm.hasNewStudent},on:{"change":function($event){return _vm.checkName(
                    _vm.parentNewStudentFirstName,
                    _vm.parentNewStudentLastName
                  )}},model:{value:(_vm.parentNewStudentLastName),callback:function ($$v) {_vm.parentNewStudentLastName=$$v},expression:"parentNewStudentLastName"}}),_c('el-input',{staticClass:"ml-2",attrs:{"placeholder":"Email","disabled":!_vm.hasNewStudent},model:{value:(_vm.parentNewStudentEmail),callback:function ($$v) {_vm.parentNewStudentEmail=$$v},expression:"parentNewStudentEmail"}})],1),_c('el-dialog',{attrs:{"title":"Select Name","visible":_vm.showSelectName,"width":"30%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.showSelectName=$event}}},[_c('el-radio',{attrs:{"label":1,"border":""},model:{value:(_vm.nameType),callback:function ($$v) {_vm.nameType=$$v},expression:"nameType"}},[_vm._v(" "+_vm._s(`${_vm.parentNewStudentFirstName}${_vm.parentNewStudentLastName}`)+" ")]),_c('el-radio',{attrs:{"label":2,"border":""},model:{value:(_vm.nameType),callback:function ($$v) {_vm.nameType=$$v},expression:"nameType"}},[_vm._v(" "+_vm._s(`${_vm.parentNewStudentLastName}${_vm.parentNewStudentFirstName}`)+" ")])],1)],1),(_vm.checkedStudent.length > 0 || _vm.hasNewStudent)?_c('el-button',{staticClass:"mt-3",attrs:{"type":"primary"},on:{"click":() => _vm.parentEnroll()}},[_vm._v(" "+_vm._s(_vm.$t("quickEnroll.i18n3"))+" ")]):_vm._e()]],2)],1):_vm._e(),(_vm.studentListAdminVisible)?_c('el-form-item',{attrs:{"label":_vm.$t('grading.Students')}},[_c('div',[_c('el-select',{staticClass:"select-test",staticStyle:{"width":"100%"},attrs:{"filterable":"","clearable":"","placeholder":"Select a student","no-data-text":"Loading..."},model:{value:(_vm.adminSelectedStudent),callback:function ($$v) {_vm.adminSelectedStudent=$$v},expression:"adminSelectedStudent"}},_vm._l((_vm.allStudents),function(student,index){return _c('el-option',{key:index,attrs:{"value":student.id,"label":student.first_name +
                ' ' +
                student.last_name +
                ' (' +
                student.email +
                ')'}})}),1),_c('div',{staticStyle:{"margin-top":"20px"}},[(_vm.adminSelectedStudent > 0)?_c('el-button',{attrs:{"type":"primary"},on:{"click":() => _vm.enterToEnroll('admin')}},[_vm._v(" "+_vm._s(_vm.$t("quickEnroll.i18n3"))+" ")]):_vm._e()],1)],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }