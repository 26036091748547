<template>
  <div class="container">
    <Breadcrumb />
    <h1>{{ this.pageTitle }}</h1>
    <hr class="separate-line" />
    <el-form label-position="top">
      <el-form-item :label="$t('quickEnroll.pleaseSelectOne')">
        <el-radio-group v-model="enrollType">
          <el-radio-button label="self" v-if="isRoleStudent()">
            {{ $t("quickEnroll.enrollForYourself") }}
          </el-radio-button>
          <el-radio-button label="parent" v-if="isRoleParent()">
            {{ $t("quickEnroll.i18n1") }}
          </el-radio-button>
          <el-radio-button label="admin" v-if="isRoleAdmin()">
            {{ $t("quickEnroll.i18n1") }} (Admin)
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="studentListVisible" :label="$t('quickEnroll.i18n2')">
        <el-alert
          :title="$t('enrollment.step2.notes')"
          :closable="false"
          type="error"
          class="mb-3"
        ></el-alert>
        <div>
          <template>
            <el-checkbox-group v-model="checkedStudent">
              <div :key="student.student_user_id" v-for="student in students">
                <el-checkbox :label="student.student_user_id">
                  {{
                    userMethods.displayName(
                      student.first_name,
                      student.last_name
                    )
                  }}
                  <b>[{{ student.email }}]</b>
                </el-checkbox>
              </div>
            </el-checkbox-group>
            <div>
              <el-checkbox v-model="hasNewStudent" :label="-1">
                {{ $t("quickEnroll.New Student") }}

                <el-input
                  class="ml-2"
                  v-model="parentNewStudentFirstName"
                  placeholder="First name"
                  :disabled="!hasNewStudent"
                  @change="
                    checkName(
                      parentNewStudentFirstName,
                      parentNewStudentLastName
                    )
                  "
                  style="width:150px"
                ></el-input>
                <el-input
                  class="ml-2"
                  v-model="parentNewStudentLastName"
                  placeholder="Last name"
                  :disabled="!hasNewStudent"
                  style="width:150px"
                  @change="
                    checkName(
                      parentNewStudentFirstName,
                      parentNewStudentLastName
                    )
                  "
                ></el-input>
                <el-input
                  class="ml-2"
                  v-model="parentNewStudentEmail"
                  placeholder="Email"
                  :disabled="!hasNewStudent"
                ></el-input>
              </el-checkbox>
              <el-dialog
                title="Select Name"
                :visible.sync="showSelectName"
                width="30%"
                :before-close="handleClose"
              >
                <el-radio v-model="nameType" :label="1" border>
                  {{
                    `${parentNewStudentFirstName}${parentNewStudentLastName}`
                  }}
                </el-radio>
                <el-radio v-model="nameType" :label="2" border>
                  {{
                    `${parentNewStudentLastName}${parentNewStudentFirstName}`
                  }}
                </el-radio>
                <!-- <span slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                </span> -->
              </el-dialog>
            </div>
            <el-button
              class="mt-3"
              v-if="checkedStudent.length > 0 || hasNewStudent"
              type="primary"
              @click="() => parentEnroll()"
            >
              {{ $t("quickEnroll.i18n3") }}
            </el-button>
          </template>
        </div>
      </el-form-item>
      <el-form-item
        v-if="studentListAdminVisible"
        :label="$t('grading.Students')"
      >
        <div>
          <el-select
            class="select-test"
            v-model="adminSelectedStudent"
            filterable
            clearable
            placeholder="Select a student"
            no-data-text="Loading..."
            style="width:100%"
          >
            <el-option
              v-for="(student, index) in allStudents"
              :key="index"
              :value="student.id"
              :label="
                student.first_name +
                  ' ' +
                  student.last_name +
                  ' (' +
                  student.email +
                  ')'
              "
            >
            </el-option>
          </el-select>
          <div style="margin-top: 20px">
            <el-button
              v-if="adminSelectedStudent > 0"
              type="primary"
              @click="() => enterToEnroll('admin')"
            >
              {{ $t("quickEnroll.i18n3") }}
            </el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { user } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import profileApi from "@/apis/profile";
import userApi from "@/apis/user";
import enrollmentApi from "@/views/enrollmentNew/apis/enrollmentNew";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  mixins: [roleMixin],
  components: { Breadcrumb },
  data() {
    return {
      parentEnrollType: "students",
      enrollType: "",
      studentListVisible: false,
      studentListAdminVisible: false,
      checkedStudent: [],
      students: [],
      allStudents: [],
      allStudentsTop30: [],
      adminSelectedStudent: null,
      parentNewStudentEmail: null,
      parentNewStudentFirstName: "",
      parentNewStudentLastName: "",
      hasNewStudent: false,
      showSelectName: false,
      nameType: 1
    };
  },
  watch: {
    enrollType(val) {
      if (val === "self") {
        this.enterToEnroll("self");
      }
      if (val === "parent") {
        this.openStudentList();
      }
      if (val === "admin") {
        this.openStudentListAdmin();
      }
    },
    nameType(){
      this.showSelectName = false;
    }
  },
  computed: {
    userMethods() {
      return user;
    },
    pageTitle() {
      return this.$t("pageTitle.QuickEnrollNew");
    }
  },
  mounted() {},
  methods: {
    isChinese(str) {
      let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (reg.test(str)) {
        return true;
      } else {
        return false;
      }
    },
    checkName(first, last) {
      if (first !== "" && last !== "") {
        if (this.isChinese(first) || this.isChinese(last)) {
          this.showSelectName = true;
        }
      }
    },
    async getStudents() {
      const students = await userApi.searchStudents();
      this.allStudents = students;
      this.allStudentsTop30 = this.allStudents.slice(0, 30);
    },
    async openStudentList() {
      this.studentListVisible = true;
      const {
        parent: { son_daughter }
      } = await profileApi.getUserRoleProfile();
      this.students = son_daughter;
      if (this.students && this.students.length > 0) {
        let removeIndex = -1;
        this.students.forEach((student, index) => {
          if (
            student.student_user_id ===
            this.$store.getters["user/getProfile"].id
          ) {
            removeIndex = index;
          }
        });
        if (removeIndex > -1) {
          this.students.splice(removeIndex, 1);
        }
      }
    },
    async openStudentListAdmin() {
      this.studentListAdminVisible = true;
      this.getStudents();
    },
    async checkEmail(email) {
      const res = await profileApi.checkEmail(email);
      let isUser = res.is_user_existing;
      return isUser;
    },
    async parentEnroll() {
      if (
        this.hasNewStudent &&
        this.parentNewStudentEmail &&
        this.parentNewStudentEmail !== ""
      ) {
        let isUser = await this.checkEmail(this.parentNewStudentEmail);
        if (isUser) {
          this.$confirm(
            this.$t("quickEnroll.has been registered", {
              email: this.parentNewStudentEmail
            }),
            "",
            {
              confirmButtonText: this.$t("quickEnroll.Contact us"),
              cancelButtonText: this.$t("quickEnroll.Use another email"),
              type: "warning"
            }
          )
            .then(async () => {
              await enrollmentApi.linkStudent(
                this.$store.getters["user/getProfile"].id,
                {
                  student_email: this.parentNewStudentEmail
                }
              );
              this.$message({
                message: "已成功通知管理員",
                type: "success"
              });
              this.parentNewStudentEmail = "";
            })
            .catch(() => {
              this.parentNewStudentEmail = "";
            });
        } else {
          let enrollmentId = 0;
          if (this.checkedStudent && this.checkedStudent.length > 0) {
            const res = await enrollmentApi.quickEnrollParent(
              this.$store.getters["user/getProfile"].id,
              { student_user_ids: this.checkedStudent }
            );
            enrollmentId = res.enrollment_form_id;
          } else {
            const res = await enrollmentApi.quickEnrollParent(
              this.$store.getters["user/getProfile"].id,
              { student_user_ids: [] }
            );
            enrollmentId = res.enrollment_form_id;
          }
          this.$router.push({
            name: "EnrollmentNew",
            query: {
              enrollment_id: enrollmentId,
              step: 2,
              isQuick: 1,
              email: this.parentNewStudentEmail,
              first: this.nameType === 1? this.parentNewStudentFirstName:this.parentNewStudentLastName,
              last: this.nameType === 1? this.parentNewStudentLastName:this.parentNewStudentFirstName
            }
          });
        }
      } else {
        this.enterToEnroll("students");
      }
    },
    async enterToEnroll(object) {
      let enrollmentId = 0;
      if (object === "parent") {
        const res = await enrollmentApi.quickEnrollParent(
          this.$store.getters["user/getProfile"].id
        );
        enrollmentId = res.enrollment_form_id;
      } else {
        let studentId = 0;
        if (object === "self") {
          studentId = this.$store.getters["user/getProfile"].id;
          const res = await enrollmentApi.quickEnroll(studentId);
          enrollmentId = res.enrollment_form_id;
        }
        if (object === "students") {
          studentId = this.checkedStudent;
          const res = await enrollmentApi.quickEnrollParent(
            this.$store.getters["user/getProfile"].id,
            { student_user_ids: studentId }
          );
          enrollmentId = res.enrollment_form_id;
        }
        if (object === "admin") {
          studentId = this.adminSelectedStudent;
          const res = await enrollmentApi.quickEnroll(studentId);
          enrollmentId = res.enrollment_form_id;
        }
      }
      this.$router.push({
        name: "EnrollmentNew",
        query: {
          enrollment_id: enrollmentId,
          step: 2,
          isQuick: 1
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__label {
  line-height: normal;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
}
::v-deep .el-form-item__content {
  padding-left: 40px;
}
::v-deep .el-radio-group {
  margin: 10px 0;
}
</style>
